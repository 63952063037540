import { years } from '@trustblock/constants/options.constants';
import { isSimpleUrl, isURL } from '@trustblock/helpers/misc';
import { Link, linkKeys } from '@trustblock/types/global.types';
import isEthereumAddress from 'validator/lib/isEthereumAddress';
import { type RefinementCtx, ZodIssueCode } from 'zod';

export function testIsUrl(value: string | undefined, context: RefinementCtx) {
  if (!isURL(value ?? '')) {
    context.addIssue({
      code: ZodIssueCode.invalid_string,
      validation: 'regex',
      message: 'Invalid URL'
    });
  }
}

export function testIsSimpleUrl(value: string | undefined, context: RefinementCtx) {
  if (!isSimpleUrl(value ?? '')) {
    context.addIssue({
      code: ZodIssueCode.invalid_string,
      validation: 'regex',
      message: `This field must be a simple URL, such as 'https://website.com'. URLs with subpaths, like 'https://website.com/subpath/whatever', are not accepted.`
    });
  }
}

export function testIsDomain(value: string, context: RefinementCtx) {
  const domainRegex =
    /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/gm;
  if (value && !domainRegex.test(value)) {
    context.addIssue({
      code: ZodIssueCode.invalid_string,
      validation: 'regex',
      message: 'Invalid Domain'
    });
  }
}

export function testAtLeastOneFieldRequired(fields: string[]) {
  return (value: Record<string, unknown>, context: RefinementCtx) => {
    const givenFields = Object.keys(value);
    if (givenFields.length < 1) {
      context.addIssue({
        code: ZodIssueCode.custom,
        message: `${context.path.join('.')} must contain at least one of the following fields: ${fields
          .map((field) => `\`${field}\``)
          .join(', ')}.`
      });
    }
  };
}

export function testIsEvmAddress(value: string, context: RefinementCtx) {
  if (value && !isEthereumAddress(value)) {
    context.addIssue({
      code: ZodIssueCode.invalid_string,
      validation: 'regex',
      message: 'Invalid EVM Address'
    });
  }
}

export function testSocialProfileLink(value: string | undefined, context: RefinementCtx) {
  const regexPerLink: Record<string, RegExp> = {
    [Link.Twitter]: /^https?:\/\/(www\.)?(twitter|x)\.com\/([a-zA-Z0-9_]+)\/?$/,
    [Link.Telegram]: /^https?:\/\/(www\.)?t\.me\/([a-zA-Z0-9_]+)\/?$/,
    [Link.Discord]: /^https?:\/\/(www\.)?discord\.(gg|com\/invite)\/[a-zA-Z0-9]+$/,
    [Link.Youtube]:
      /^https?:\/\/(www\.)?youtube\.com\/(channel\/[a-zA-Z0-9_-]+|user\/[a-zA-Z0-9_-]+|[a-zA-Z0-9_-]+)\/?$/,
    [Link.Linkedin]: /^https?:\/\/(www\.)?linkedin\.com\/in\/([a-zA-Z0-9_-]+)\/?$/,
    [Link.Github]: /^https?:\/\/(www\.)?github\.com\/([a-zA-Z0-9_-]+)(\/[a-zA-Z0-9_.-]+)?\/?$/
  };
  const key = context.path.find((path) => linkKeys.includes(path as Link)) as string;

  if (value && !regexPerLink[key].test(value)) {
    context.addIssue({
      code: ZodIssueCode.invalid_string,
      validation: 'regex',
      message: `Invalid ${key} URL`
    });
  }
}

export function testIsValidYear(value: number | undefined, context: RefinementCtx) {
  if (value && !years.includes(value)) {
    context.addIssue({
      code: ZodIssueCode.custom,
      message: 'Invalid Year'
    });
  }
}
