import type { LinksValidator } from '@trustblock/validators/global.validators';

export enum Link {
  Website = 'website',
  Twitter = 'twitter',
  Discord = 'discord',
  Telegram = 'telegram',
  Github = 'github',
  Youtube = 'youtube',
  Linkedin = 'linkedIn'
}
export const linkKeys = Object.values(Link);

export enum Tag {
  Analytics = 'analytics',
  Collectibles = 'collectibles',
  Finance = 'finance',
  Gaming = 'gaming',
  Security = 'security',
  Social = 'social',
  Wallet = 'wallet',
  Other = 'other'
}
export const tags = Object.values(Tag);

export enum FileType {
  Report = 'report',
  ProfilePicture = 'profile_picture'
}

export interface RequestResponseBase {
  extra: {
    requestId: string;
  };
}

export interface RequestResponsePagination<T> {
  pagination: {
    page: number;
    totalItems: number;
    totalPages: number;
  };
  items: T[];
}

export type DistributedOmit<T, K extends keyof never> = T extends unknown ? Omit<T, K> : never;

type PickNullable<T> = {
  [P in keyof T as null extends T[P] ? P : never]: T[P];
};

type PickNotNullable<T> = {
  [P in keyof T as null extends T[P] ? never : P]: T[P];
};

export type NullToOptional<T> = {
  [K in keyof PickNullable<T>]?: Exclude<T[K], null>;
} & {
  [K in keyof PickNotNullable<T>]: T[K];
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};

export type LinksPrivate = LinksValidator;
export type LinksPublic = Omit<LinksValidator, 'targetId'>;
