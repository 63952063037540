import { cn } from '@trustblock/helpers/classname';
import { tryPluralizeWord } from '@trustblock/helpers/formatters';
import type React from 'react';
import { useCallback, useState } from 'react';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
import ReactSelect, {
  type ClearIndicatorProps,
  type ControlProps,
  type IndicatorsContainerProps,
  type InputProps as ReactSelectInputProps,
  type MenuProps,
  type OptionProps,
  type PlaceholderProps,
  components,
  type OnChangeValue,
  type ValueContainerProps
} from 'react-select';
import Icon, { type IconName } from '../../Icon/Icon';
import type { InputProps } from '../input.types';
import './InputSelect.scss';

type SelectProps<T extends boolean, K extends FieldValues> = {
  options: {
    label: string;
    icon?: IconName;
    value: string;
  }[];
  isMulti?: T;
  onlyIconValues?: boolean;
  hideErrorMessage?: boolean;
  onChange?: (value: string | string[] | null) => void;
  isClearable?: boolean;
  control?: Control<K>;
} & Omit<InputProps<K>, 'onChange' | 'control'> &
  Omit<Partial<ReactSelectInputProps>, 'onChange' | 'control'>;

interface Option {
  label: string;
  icon?: IconName;
  value: string;
}

const customInput = (props: ReactSelectInputProps<Option>) => {
  return <components.Input className={cn('font-medium text-body')} {...props} />;
};

const customPlaceholder = (props: PlaceholderProps<Option>) => (
  <components.Placeholder className="font-medium text-black50 text-body" {...props} />
);

const customDropdownIndicatorContainer = (props: IndicatorsContainerProps<Option>) => (
  <components.IndicatorsContainer
    className="flex items-center justify-center h-full p-0 transition-colors duration-2 ease-out"
    {...props}
  />
);

const customDropdownIndicator = () => (
  <div
    data-testid="tb-input-select-arrow-indicator"
    className="flex items-center justify-center h-full px-3 transition-colors cursor-pointer hover:bg-white100"
  >
    <Icon
      name="ArrowDown"
      className="h-4 w-4 stroke-black50 transition-transform duration-3 ease-out group-[.is-open]:rotate-180"
    />
  </div>
);

const customClearIndicator = (props: ClearIndicatorProps<Option>) => (
  <components.ClearIndicator {...props} className="flex items-center justify-center h-full hover:bg-white100">
    <Icon name="X" className="w-10 px-3 text-black50" />
  </components.ClearIndicator>
);

const customIndicatorSeparator = () => <div className="w-px transition-colors duration-2 ease-out h-3/4 bg-white107" />;

const customOption = (props: OptionProps<Option>) => (
  <components.Option {...props}>
    <div className="flex items-center px-3 py-2 cursor-pointer text-label hover:bg-white100">
      {props.data.icon && <Icon name={props.data.icon} className="w-4 h-4 mr-2" />}
      <span>{props.data.label}</span>
    </div>
  </components.Option>
);

const customValueContainer = (props: ValueContainerProps<{ icon?: IconName; value: string; label: string }>) => {
  if (props.isMulti) {
    const options = (props.children as React.ReactNode[])[0] as React.ReactNode[];
    const optionsTotal = options?.length ?? 0;

    return (
      <components.ValueContainer className={cn('flex items-center px-3 valueContainer')} {...props}>
        {optionsTotal > 0 && (
          <span className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">{`${optionsTotal} ${tryPluralizeWord(
            'option',
            optionsTotal
          )} selected`}</span>
        )}
        {props.children}
      </components.ValueContainer>
    );
  }
  const currentValue = props.getValue();
  const optionIcon = currentValue?.[0]?.icon ?? null;

  return (
    <components.ValueContainer {...props} className="cursor-pointer !flex items-center px-3">
      {optionIcon && !!(props.children as React.ReactNode[])?.[0] && <Icon name={optionIcon} className="mr-2 size-4" />}
      {props.children}
    </components.ValueContainer>
  );
};

const customMenu = (props: MenuProps<Option>) => (
  <components.Menu
    {...props}
    className="py-1 mt-1 border rounded-md shadow-md border-white107 bg-white90 animate-fadeinup duration-2"
  >
    {props.children}
  </components.Menu>
);

function InputSelect<T extends boolean, K extends FieldValues>({
  placeholder,
  label,
  validationRules,
  fieldName,
  readOnly,
  error,
  options,
  control,
  isMulti,
  icon,
  onChange,
  onlyIconValues,
  hideErrorMessage,
  isClearable = true,
  ...restProps
}: SelectProps<T, K>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customControlWithIcon = useCallback(
    (props: ControlProps<Option>) => (
      <components.Control
        {...props}
        className={cn(
          'border-box flex h-9 !min-h-9 w-full items-center overflow-hidden rounded-md border border-white107 bg-white90 transition-all duration-3 ease-out',

          {
            'border border-error': error,
            'focus-within:border-primary': !error
          }
        )}
      >
        {icon && (
          <div className="flex items-center h-full ml-3">
            <Icon name={icon} className="w-4 h-4 mr-2 stroke-black50" />
            <div className="h-[70%] w-px bg-white107 transition-all duration-3 ease-out" />
          </div>
        )}
        {props.children}
      </components.Control>
    ),
    [icon, error]
  );

  return (
    <div
      className={cn('cursor-pointer flex flex-col gap-1', { 'cursor-not-allowed opacity-70': readOnly })}
      {...restProps}
    >
      {label && (
        <label htmlFor={fieldName} className="block text-black70 text-input-label">
          {label}
        </label>
      )}
      <Controller
        name={fieldName as Path<K>}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <ReactSelect
            hideSelectedOptions={false}
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect={false}
            isClearable={isClearable}
            className={cn(
              'border-box flex h-9 min-w-[170px] items-center text-body transition-all duration-3 ease-out group font-medium',
              {
                'is-open': isMenuOpen
              }
            )}
            {...field}
            value={
              isMulti
                ? options.filter((option) => (field.value as string | undefined)?.includes(option.value))
                : options.find((option) => option.value === field.value)
            }
            onChange={(selectedOptions: OnChangeValue<Option, T>) => {
              if (selectedOptions && 'value' in selectedOptions) {
                if (onChange) {
                  onChange(selectedOptions.value);
                }

                return field.onChange(selectedOptions.value);
              }

              let selectedValues = selectedOptions
                ? selectedOptions.map((selectedOption) => selectedOption.value)
                : null;
              if (selectedValues?.length === 0) {
                selectedValues = null;
              }
              if (onChange) {
                onChange(selectedValues);
              }
              return field.onChange(selectedValues);
            }}
            classNames={{
              option: ({ isSelected }) => (isSelected ? 'bg-white104 hover:bg-white104' : ''),
              control: ({ isDisabled }) => (isDisabled ? 'cursor-not-allowed opacity-70' : '')
            }}
            options={options}
            isDisabled={readOnly}
            placeholder={placeholder}
            instanceId={fieldName}
            components={{
              Placeholder: customPlaceholder,
              DropdownIndicator: customDropdownIndicator,
              Option: customOption,
              Control: customControlWithIcon,
              ValueContainer: customValueContainer,
              Menu: customMenu,
              ClearIndicator: customClearIndicator,
              IndicatorsContainer: customDropdownIndicatorContainer,
              IndicatorSeparator: customIndicatorSeparator,
              Input: customInput
            }}
            isMulti={isMulti}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            unstyled
          />
        )}
      />
      {error && !hideErrorMessage && <div className="text-error text-label">{error}</div>}
    </div>
  );
}

export default InputSelect;
