import validatorIsURL from 'validator/lib/isURL';

export function isURL(str: string) {
  return validatorIsURL(str, { require_host: true, require_protocol: true, require_tld: true, protocols: ['https'] });
}

export function isSimpleUrl(str: string) {
  try {
    const url = new URL(str);
    return url.pathname === '/' && isURL(str);
  } catch {
    return false;
  }
}
