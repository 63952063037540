import { cn } from '@trustblock/helpers/classname';
import type React from 'react';
import type { FormEvent } from 'react';
import { type FieldValues, FormProvider, type UseFormReturn } from 'react-hook-form';

interface FormProps<T extends FieldValues> {
  children: React.ReactNode;
  onSubmit?: (e: FormEvent) => void;
  className?: string;
  // biome-ignore lint/suspicious/noExplicitAny: no other choice here
  formMethods?: UseFormReturn<T, any, undefined>;
}

function Form<T extends FieldValues>({ children, onSubmit, className, formMethods, ...props }: FormProps<T>) {
  const formContent = (
    <form
      {...props}
      className={cn(
        'mb-5 flex w-full flex-col justify-center gap-6 text-left [&>section>*:not(:last-child)]:mb-3 [&>section>*:last-child]:mb-2',
        className
      )}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
  if (!formMethods) {
    return formContent;
  }
  return <FormProvider {...formMethods}>{formContent}</FormProvider>;
}

export default Form;
